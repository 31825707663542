import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { FiShield } from "react-icons/fi"

export default function Home() {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulOrganization {
            title
            subtitle
            phone
            email
            address
          }
        }
      `}
      render={data => (
        <nav className="py-5">
          <div className="container mx-auto px-4 flex items-center justify-between md:flex-row flex-col space-y-4">
            <Link
              to="/"
              className="flex content-center items-center tracking-wide text-2xl subpixel-antialiased font-bold uppercase whitespace-nowrap text-gray-800"
            >
              <span className="rounded-full h-7 w-7 flex items-center justify-center bg-red-800 mr-2 text-xl">
                <FiShield className="text-yellow-50 " />
              </span>
              <span>{data.contentfulOrganization.title}</span>
            </Link>

            <a
              href={`tel:${data.contentfulOrganization.phone.replace(
                /\D/g,
                ""
              )}`}
              className="whitespace-nowrap text-red-800 bg-transparent border-2 border-solid border-red-800 hover:bg-red-800 hover:text-white active:bg-red-600 font-semibold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-100"
            >
              {data.contentfulOrganization.phone}
            </a>
            {/* Mobile menu */}
          </div>
        </nav>
      )}
    />
  )
}
