import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, url, lang, noindex }) => {
  const link = "https://bnconsult.com.ua"
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulAsset(id: { eq: "e4d8fd4c-643f-5526-b5fa-806e29e0489d" }) {
            gatsbyImageData
            fixed(width: 1200, height: 630, quality: 85) {
              src
            }
          }
        }
      `}
      render={data => (
        <Helmet>
          <html lang={lang} />
          <meta charset="UTF-8" />
          {/* General tags */}
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="image"
            content={`https:${data.contentfulAsset.fixed.src}`}
          />
          <link rel="canonical" href={`${link}${url}`} />

          {noindex === "noindex" ? (
            <meta name="robots" content="noindex" />
          ) : (
            ``
          )}

          {/* OpenGraph tags */}
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${link}${url}`} />
          <meta property="og:locale" content={lang} />
          <meta
            property="og:locale:alternate"
            content={lang === "ru" ? "uk" : "ru"}
          />
          <meta property="og:site_name" content="BN Consult" />
          <meta
            property="og:image"
            content={`https:${data.contentfulAsset.fixed.src}`}
          />
          <meta property="og:image:width" content="620" />
          <meta property="og:image:height" content="460" />
          <meta property="fb:app_id" content="1209612886135351" />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@shivasena" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content={`https:${data.contentfulAsset.fixed.src}`}
          />
          {/* https://github.com/nfl/react-helmet */}
        </Helmet>
      )}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  url: "/",
  lang: "uk",
}

export default SEO
